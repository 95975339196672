@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@200;300;400;500;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css");

body {
  margin: 0;
}
.hero {
  padding: 30px;
  width: 100%;
}
nav {
}
.banner {
  width: 100% !important;
}
.hero-div {
  background-image: url("./assets/images/banner-shap.png"),
    linear-gradient(rgb(199, 236, 255) 0px, rgb(249, 239, 255) 100%);
}
.kolonlar {
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 60px;
  text-align: center !important;
  color: #ae328b;
}
.kolonlar2 {
  font-weight: 700;
  letter-spacing: -0.03em;
  line-height: 26px;
  text-align: center !important;
  color: #ae328b;
  font-size: 20px;
}

.kart {
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 1px 1px 10px #eee;
  background-color: white;
  border-radius: 10px;
}
.mobile-container {
  margin-left: 10px;
  margin-right: 10px;
}
.merkez {
  text-align: center !important;
}
.purple-bg {
  margin: 40px 0;
  background-color: #ae328b;
  color: white;
  padding: 30px 10px;
  text-align: center;
  background-image: url("./assets/images/dot-pattern.png");
  border-top: 2px solid #7a2361;
  border-bottom: 2px solid #7a2361;
}

.kart2 {
  border: 1px solid #7a2361;
  padding: 20px;
  box-shadow: 1px 1px 10px #7b2362;
  background-color: white;
  border-radius: 5px;
}

.mor {
  color: #ae328b !important;
  width: 40px !important;
  height: 40px !important;
}

.customers-bg {
  background: linear-gradient(to bottom, #fffaf5 0, #f8ecff 100%);
  background-image: linear-gradient(
    rgb(255, 250, 245) 0px,
    rgb(248, 236, 255) 100%
  );

  border-top: 1px solid rgb(227, 204, 240);
  border-bottom: 1px solid rgb(227, 204, 240);
}

.customers-div {
  text-align: center;
  padding: 30px 10px;
}
.customers-card {
  padding: 5px;
  background-color: white;
  border: 1px solid rgb(227, 204, 240);
  box-shadow: 1px 1px 10px rgb(227, 204, 240);
  border-radius: 5px;
}
